import { ReactComponent as Logo } from "assets/images/iparque-horizontal.svg";
import ContentContainer from "components/ContentContainer";
import MaxWidthContainer from "components/MaxWidthContainer";
import useLanguage from "hooks/useLanguage";
import { Button, Overlay, usePublicPagePopupsContext } from "iparque-components";
import React, { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import { videoIds } from "./configs";
import {
  LogoContainer,
  StyledFullPageContainer,
  StyledHeading,
  StyledSubHeading,
  TextContainer,
} from "./styles";

const CityAnimation = React.lazy(() => import("./CityAnimation"));

const Head: React.FC = () => {
  const { t } = useTranslation();
  const language = useLanguage();
  const [videoVisible, setVideoVisible] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const { paddingTop, animationDuration } = usePublicPagePopupsContext();

  const videoId = videoIds[language];

  const showVideo = () => setVideoVisible(true);

  const closeVideo = () => setVideoVisible(false);

  const onLottieReady = () => setShouldAnimate(true);

  return (
    <StyledFullPageContainer
      shouldAnimate={shouldAnimate}
      extraPaddingTop={paddingTop}
      extraPaddingTopAnimationDuration={animationDuration}
    >
      <MaxWidthContainer>
        <LogoContainer>
          <Logo
            width="200px"
            role="img"
            aria-labelledby={t("10661") /* Logo da solução de gestão de estacionamento */}
            title={t("10661") /* Logo da solução de gestão de estacionamento */}
          />
        </LogoContainer>
      </MaxWidthContainer>
      <ContentContainer>
        <Suspense fallback={null}>
          <CityAnimation onLottieReady={onLottieReady} />
        </Suspense>
        <TextContainer shouldAnimate={shouldAnimate}>
          <div>
            <StyledHeading variant="h1">
              {t("9163") /* Gestão de estacionamento */}
            </StyledHeading>
          </div>
          <StyledSubHeading variant="h2">
            {
              t(
                "9164",
              ) /* Todos os módulos necessários para condutores e concessionárias. */
            }
          </StyledSubHeading>
          <Button
            color="quaternary"
            className="uppercase mt-30"
            onClick={showVideo}
            title={
              t(
                "10680",
              ) /* Todos os módulos necessários para a gestão de estacionamentos */
            }
          >
            {t("10132") /* Ver vídeo */}
          </Button>
        </TextContainer>
      </ContentContainer>
      <Overlay visible={videoVisible} onClose={closeVideo}>
        {videoId && (
          <iframe
            title="iparque-video"
            src={`https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1`}
            width="100%"
            height="100%"
            allow="autoplay;fullscreen"
            frameBorder={0}
          />
        )}
      </Overlay>
    </StyledFullPageContainer>
  );
};

export default Head;
